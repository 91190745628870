const humanReadableFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumSignificantDigits: 3 });

export function formatHumanReadableNumber(num) {
    return humanReadableFormatter.format(num)
}

export function formatCurrencyNumber(ccy, num) {
    const ccyFormatter = Intl.NumberFormat('en', { style: 'currency', currency: ccy });
    return ccyFormatter.format(num)
}
