import { Box, Typography, Stack, Button, FormControlLabel, Switch, Divider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getGroupDetail } from "../backend";
import { AvatarStack } from "../components/AvatarStack";
import { NewExpense } from "../components/NewExpense";
import { Loader } from "../components/Loader";
import { GroupDetailContext, GroupResetContext, UserContext } from "../contexts";
import { SuggestedPayments } from "../components/SuggestedPayments";
import { ExpensesList } from "../components/Expenses";
import { HorizontalRule } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AllPayments } from "../components/AllPayments";

export const Group = ({ targetGroup }) => {
    const user = useContext(UserContext)
    const { group_id, group_name, group_hash, description } = targetGroup;
    const [groupDetail, setGroupDetail] = useState(null)
    const [hideDeleted, setShowDeleted] = useState(true)
    const [groupReset, setGroupReset] = useState({})
    useEffect(() => {
        getGroupDetail(user, group_id).then(data => {
            setGroupDetail(data)
        })
        return () => { }
    }, [user?.email, groupReset])
    return (
        groupDetail == null ? <Loader /> :
            <GroupResetContext.Provider value={[groupReset, setGroupReset]}>
                <Box>
                    {groupDetail == null ? <Loader /> :
                        <GroupDetailContext.Provider value={groupDetail}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: "space-between",
                                    backgroundColor: "background.default"
                                }}>
                                <Stack spacing={0.5}>
                                    <Typography variant="h5">{group_name}</Typography>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'none',
                                            borderRadius: 1,
                                            fontSize: 10,
                                            p: 0.4,
                                            width: "15ch"
                                        }}
                                        onClick={() => { navigator.clipboard.writeText(group_hash.toLowerCase()) }}
                                    >
                                        <Typography variant="caption">{group_hash.toLowerCase()}</Typography>
                                        <Box mx={0.2} />
                                        <ContentCopyIcon variant="caption" fontSize="8px" />
                                    </Button>
                                </Stack>
                                <Box flexShrink={1}>
                                    <AvatarStack users={groupDetail.users} />
                                </Box>
                            </Box>
                            <SuggestedPayments />
                            <AllPayments groupDetail={groupDetail} sx={{ borderRadius: 1, my: 0.5 }} />
                            <NewExpense sx={{ borderRadius: 1, my: 0.5 }} />
                            <Box mt={1} />
                            <Divider m={2} />
                            <FormControlLabel fontSize={2} sx={{ font: 3 }} control={
                                <Switch defaultChecked onChange={e => setShowDeleted(e.target.checked)} />
                            } label="Hide deleted" />
                            <ExpensesList hideDeleted={hideDeleted} />
                        </GroupDetailContext.Provider>
                    }
                </Box >
            </GroupResetContext.Provider >
    )

}