
import React from "react";

export const UserContext = React.createContext();
export const PageContext = React.createContext();
export const TargetGroupContext = React.createContext();
export const DialogContext = React.createContext();
export const GroupDetailContext = React.createContext();
export const GroupResetContext = React.createContext();
export const GroupsResetContext = React.createContext();
export const SplitContext = React.createContext();
