import React, { useState } from "react";
import Topbar from "../components/Topbar";
import { TargetGroupContext } from "../contexts";
import { Group } from "../pages/Group";
import { Home } from "../pages/Home";

export const IndexView = () => {
    const [targetGroup, setTargetGroup] = useState(null)
    return (
        <TargetGroupContext.Provider value={[targetGroup, setTargetGroup]}>
            <Topbar />
            {
                (targetGroup == null)
                    ? <Home />
                    : <Group targetGroup={targetGroup} />
            }
        </TargetGroupContext.Provider>
    );
};
