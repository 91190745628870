// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6PJ8DfaT4su6yWGzsVc6k1P4eyGPOmJw",
  authDomain: "flitwise.firebaseapp.com",
  projectId: "flitwise",
  storageBucket: "flitwise.appspot.com",
  messagingSenderId: "246727950875",
  appId: "1:246727950875:web:0e2ac28fffd45ca2866ebe",
  measurementId: "G-QQ1RQF3BQ2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
