import axios from "axios";

const HOST = process.env.REACT_APP_BACKEND_HOST;
const AUTH_SECRET = process.env.REACT_APP_AUTH_SECRET;

const ENDPOINTS = {
    GROUP: `${HOST}/v1/group`,
    GROUP_ADD: `${HOST}/v1/group/add_user`,
    GROUP_HASH: `${HOST}/v1/group/hash`,
    GROUPS: `${HOST}/v1/groups`,
    USER_GROUPS: `${HOST}/v1/user/groups`,
    USER: `${HOST}/v1/user`,
    EXPENSE: `${HOST}/v1/expense`,
    EXPENSES: `${HOST}/v1/expenses`,
    // USER = `${HOST}/v1/user/groups`
}
function getHeaders(user) {
    // const hash = hmacSHA512("", `${AUTH_SECRET}${user.email}`)
    return {
        "authorization": user.accessToken, 
        "email": user.email
    }
}

export async function getGroups(user) {
    let headers = getHeaders(user);
    const data = await axios.get(ENDPOINTS.USER_GROUPS, {
        params: { email: user.email },
        headers: headers})
    return data;
}

export async function getExpenses(user, group_id) {
    const data = await axios.get(ENDPOINTS.EXPENSES, {
        params: {
            group_id: group_id
        },
        headers: getHeaders(user)
    })
    return data["data"]["data"];
}

export async function createGroup(user, groupName, description, userId) {
    await axios.post(`${ENDPOINTS.GROUPS}`, {
        group_name: groupName, description, users: [userId]
    }, {headers: getHeaders(user)})
}

export async function getGroupDetail(user, groupId) {
    let data = await axios.get(`${ENDPOINTS.GROUP}/${groupId}`, {headers: getHeaders(user)})
    data = data["data"];
    data.users = Object.fromEntries(data.users.map(user => [user.user_id, user]))
    return data;
}

export async function joinGroup(user, groupId, email) {
    let data = await axios.post(`${ENDPOINTS.GROUP_ADD}`, {
        group_id: groupId, email
    }, {headers: getHeaders(user)})
    return data
}

export async function getGroupByHash(groupHash, abortControllerSignal) {
    let data = await axios.get(`${ENDPOINTS.GROUP_HASH}/${groupHash}`,
        { signal: abortControllerSignal }
    )
    data = data["data"];
    return data != null ? data : {};
}

export async function patchUser(user) {
    let headers = getHeaders(user)
    user = { display_name: user.displayName, photo_url: user.photoURL, email: user.email }
    return await axios.patch(ENDPOINTS.USER, user, {"headers": headers})
}

export async function createNewExpense({
    user, group_id, user_id, currency_id, amount, description, splits
}) {
    /*
        group_id: i32,
        currency_id: i32,
        created_by_user_id: i32,
        description: String,
        expense_type: String,
        splits: Vec<CreateSplitPayload>,

        user_id: i32,
        is_payer: bool,
        amount: f64,
    */
    splits = Object.entries(splits).map(([user_id, amount], _) => ({
        user_id: parseInt(user_id),
        amount: -(Math.round(amount * 100) / 100),
        is_payer: false
    })).filter(split => split.amount != 0)
    splits.push({ user_id, is_payer: true, amount: Math.round(amount * 100) / 100 })
    const body = {
        group_id, currency_id, created_by_user_id: user_id, description,
        expense_type: "EXPENSE", splits
    }
    return await axios.post(ENDPOINTS.EXPENSE, body, {headers: getHeaders(user)})
}

export async function deleteExpense(user, expense_id, group_id, currency_id) {
    let body = { expense_id, group_id, currency_id }
    return await axios.delete(ENDPOINTS.EXPENSE, { data: body, headers: getHeaders(user)})
}
