import { TextField, Stack, Box, Typography, Avatar, Divider, Paper, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, Breadcrumbs, Tooltip, ButtonGroup, useColorScheme } from "@mui/material";
import { React, useContext, useRef, useState } from "react";
import { GroupDetailContext, SplitContext } from "../../contexts";
import { AvatarOrDefault } from "../AvatarStack";

export const ShareSplit = () => {
    var { users } = useContext(GroupDetailContext)
    var [amount, splits, currency] = useContext(SplitContext)
    var splitShares = Object.fromEntries(Object.values(users).map(user => ([user.user_id, 1])));
    var [usersToSplitWith, setUsersChecked] = useState(splitShares)

    function handleCheckbox(event, user) {
        if (isNaN(event.target.value)) {
            event.preventDefault();
            return
        }
        let num = parseInt(event.target.value)
        num = isNaN(num) ? 0 : num
        setUsersChecked(prev => {
            let new_state = { ...prev }
            new_state[user.user_id] = parseInt(num)
            return new_state
        })
    }
    const numShares = Object.values(usersToSplitWith).reduce((x, y) => parseInt(x) + parseInt(y));
    const splitAmount = amount / Math.max(numShares, 1);
    splits.current = Object.fromEntries(
        Object.entries(usersToSplitWith).map(([user_id, shares], k) => [user_id, shares * splitAmount])
    )
    return (
        <Box display="flex" justifyContent="center" flexDirection="column"
            elevation={0}
            sx={{ mx: 1 }}>
            {Object.values(users).map((user, key) => (
                <Box key={user.user_id} display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 0.2 }}>
                    <AvatarOrDefault user={user} />
                    <Typography>{user.display_name}</Typography>
                    <Box display="flex" flexDirection="row" alignItems={"flex-end"}>
                        <Typography variant="subtitle2" sx={{ mr: 0.5 }}>{parseInt(splits.current[user.user_id] * 100) / 100}</Typography>
                        <TextField
                            size="small"
                            sx={{
                                width: "7ch",
                            }}
                            value={usersToSplitWith[user.user_id]}
                            onChange={(event) => handleCheckbox(event, user)} />
                    </Box>
                </Box>
            ))
            }
        </Box >
    )
}
