import {
    Backdrop, MenuItem, TextField, Grid, Box, Typography, FormControl, Stack,
    InputLabel, OutlinedInput, InputAdornment, Button, CircularProgress, ButtonGroup,
    Accordion,
    AccordionSummary
} from "@mui/material";
import { CURRENCIES, SPLIT_TYPES } from "../models";
import { useContext, useRef, useState } from "react";
import { EqualSplit } from "./split/EqualSplit";
import { DetailSplit } from "./split/DetailSplit";
import { ShareSplit } from "./split/ShareSplit";
import { GroupDetailContext, GroupResetContext, SplitContext, TargetGroupContext, UserContext } from "../contexts";
import { createNewExpense } from "../backend";
import { AvatarOrDefault } from "./AvatarStack";
import { Add } from "@mui/icons-material";

const MAX = 50;

export const NewExpense = ({sx}) => {
    var [description, setDescription] = useState("")
    var [targetSplitType, setSplitType] = useState(SPLIT_TYPES.EQUAL)
    var [currency, setCurrency] = useState(CURRENCIES[2])
    var [amount, setAmount] = useState("0")
    var [isSubmitting, setIsSubmitting] = useState(false)
    var user = useContext(UserContext)
    var [group, _] = useContext(TargetGroupContext)
    var [_, setGroupReset] = useContext(GroupResetContext)
    var { users } = useContext(GroupDetailContext)
    var payerId = useRef(user.userId)
    var splits = useRef({})

    function clearMainInputs() {
        setDescription("")
        setAmount(0)
    }

    function handleSubmit() {
        setIsSubmitting(true)
        createNewExpense({
            user, group_id: group?.group_id, user_id: payerId.current, currency_id: currency.currency_id,
            amount: parseFloat(amount), description: description.trim(), splits: splits.current
        }).then((resp) => {
            clearMainInputs()
            setGroupReset({})
        }).catch((err) => {
            console.log("unable to create expense", err)
        }).finally(() => { setIsSubmitting(false) })
    }
    function handleDescriptionUpdate(event) {
        const descriptionText = event.target.value
        if (descriptionText.length > MAX) {
            event.preventDefault();
            return;
        }
        setDescription(descriptionText);
    }
    return (
        <Accordion elevation={1} sx={sx}>
            <AccordionSummary expandIcon={<Add />}
                aria-controls="panel1-content"
                id="panel1-header">Add Expense</AccordionSummary>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box display="Flex" flexDirection="column" sx={{ px: 1 }}>
                <Grid container alignItems={"flex-end"} spacing={0.5} p={1}>
                    <Grid item xs={11}>
                        <TextField
                            // className={classes.questionField}
                            id="utlined-multiline-flexible"
                            label="New Expense"
                            rows={2}
                            placeholder="Enter a description"
                            fullWidth
                            size="small"
                            value={description}
                            onChange={(event) => handleDescriptionUpdate(event)}
                        />
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle2">{MAX - description.length}</Typography>
                    </Grid>
                </Grid>
                <Box>
                    <FormControl fullWidth sx={{ m: 1, maxWidth: "30ch" }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Payer"
                            size="small"
                            defaultValue={user.userId}
                            onChange={(event) => { payerId.current = event.target.value }}
                        >
                            {Object.values(users).map((payer) => (
                                <MenuItem key={payer.user_id} value={payer.user_id}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <AvatarOrDefault user={payer} sx={{ height: '25px', width: '25px' }} />
                                        <Typography>{payer.display_name}</Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Box>
                <Box display="flex" alignItems={"stretch"}>
                    <FormControl sx={{ m: 1, minWidth: "10ch", width: "10ch" }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Currency"
                            size="small"
                            defaultValue={2}
                            onChange={(event) => { setCurrency(CURRENCIES[event.target.value]) }}
                        >
                            {Object.values(CURRENCIES).map((ccy) => (
                                <MenuItem key={ccy.value} value={ccy.currency_id}>
                                    {ccy.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "15ch" }}>
                        <InputLabel>Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            placeholder="0.00"
                            size="small"
                            startAdornment={<InputAdornment position="start"></InputAdornment>}
                            label="Amount"
                            value={amount}
                            onChange={(event) => { setAmount(event.target.value) }}

                        />
                    </FormControl>
                </Box>
                <Box display="flex" justifyContent="center" marginY={1}>
                    <ButtonGroup variant="outlined" aria-label="Basic button group" size="small">
                        {Object.keys(SPLIT_TYPES).map((splitType) => (
                            <Button key={splitType} color={splitType == targetSplitType ? "secondary" : "primary"}
                                onClick={() => { setSplitType(splitType) }}>{splitType}</Button>
                        ))}
                    </ButtonGroup>
                </Box>
                <SplitContext.Provider value={[amount, splits, currency]}>
                    {targetSplitType == SPLIT_TYPES.EQUAL ? <EqualSplit /> : <ShareSplit />}
                </SplitContext.Provider>
                <Button onClick={handleSubmit} disabled={description.trim() == "" || isNaN(amount)}>Submit</Button>
            </Box>
        </Accordion>
    )
}