import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';

export const SPLIT_TYPES = { EQUAL: "EQUAL", SHARES: 'SHARES' }
export const Pages = {
    Home: "Home",
    Group: "Group",
}
export const GROUP_STATE = {
    NEW_EXPENSE: {name: "add", icon: <AddIcon/>, key: "NEW_EXPENSE"},
    EXPENSES: {name: "all", icon: <ListIcon/>, key: "EXPENSES"},
}

// TODO: Load in currencies 
export const CURRENCIES = {
    2: {
        currency_id: 2,
        value: 'SGD',
    },
    1: {
        currency_id: 1,
        value: 'USD',
    },
    3: {
        currency_id: 3,
        value: 'IDR',
    },
    4: {
        currency_id: 4,
        value: 'VND',
    },
};
