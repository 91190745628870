import { makeStyles } from "@mui/styles"
import { Stack, Card, CardActionArea, Paper, Box, Typography, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, TextField, FormControl, OutlinedInput, FormHelperText, InputAdornment, InputLabel, FilledInput, Button, CircularProgress } from "@mui/material";
import { Add, Send } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { GroupsResetContext, TargetGroupContext, UserContext } from "../contexts";
import { AvatarStack } from "./AvatarStack";
import { createGroup, getGroupByHash, getGroupDetail, joinGroup } from "../backend";
import { BalanceSummary } from "./BalanceSummary";
import { Loader } from "./Loader";

const useStyles = makeStyles({
    groupPaper: {
        borderRadius: 15,
    },
    groupBox: {
        padding: "20px 30px 15px 30px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    time: {
        opacity: 0.7,
    },
});

export const GroupItem = ({ group }) => {
    const { group_id, group_name, description } = group;
    const classes = useStyles();
    const [_, setTargetGroup] = useContext(TargetGroupContext)
    const user = useContext(UserContext)
    const [groupDetail, setGroupDetail] = useState(null)
    useEffect(() => {
        if (user == null) {
            return 
        }
        getGroupDetail(user, group_id).then(data => {
            setGroupDetail(data)
        })
        return () => { }
    }, [user?.userId])
    return (
        groupDetail == null ? <Loader /> :
            <Card elevation={3} className={classes.groupPaper}>
                <CardActionArea onClick={() => { setTargetGroup(group) }}>
                    <Box className={classes.groupBox}>
                        <Box>
                            <Typography variant="h6">{group_name}</Typography>
                            <Box m={1} />
                            <Typography sx={{ typography: { sm: 'subtitle1', xs: 'body2' } }}>{description}</Typography>
                            <BalanceSummary user={user} balances={groupDetail?.balances} />
                        </Box>
                        <AvatarStack users={groupDetail?.users} />
                    </Box>
                </CardActionArea>
            </Card>
    );
}

const MAX_INPUT_LENGTH = 20;

export const AddNewGroup = ({ sx }) => {
    const [groupName, setGroupName] = useState("")
    const [description, setDescription] = useState("")
    const user = useContext(UserContext)
    const [_, setGroupsRefresh] = useContext(GroupsResetContext)
    function handleGroupNameChange(event, setFunction) {
        let text = event.target.value;
        if (text.length > MAX_INPUT_LENGTH) {
            event.preventDefault()
            return
        }
        setFunction(text)
    }
    function handleCreateGroup() {
        createGroup(user, groupName, description, user.userId)
            .then((_) => {
                setGroupsRefresh({})
            }).catch((err) => { console.log("Unable to create group:", err) })
            .finally(() => {
                setDescription("")
                setGroupName("")
            });
    }
    return (
        <Accordion sx={sx}>
            <AccordionSummary expandIcon={<Add />}
                aria-controls="panel1-content"
                id="panel1-header"><Typography variant="body2">NEW</Typography></AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Group name</InputLabel>
                        <OutlinedInput
                            variant="outlined"
                            label="Group name"
                            endAdornment={<InputAdornment position="end">{MAX_INPUT_LENGTH - groupName.length}</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{ 'aria-label': 'weight' }}
                            value={groupName}
                            onChange={(event) => handleGroupNameChange(event, setGroupName)}
                        />
                    </FormControl>
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Description</InputLabel>
                        <OutlinedInput
                            variant="outlined"
                            label="Description"
                            endAdornment={<InputAdornment position="end">{MAX_INPUT_LENGTH - description.length}</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{ 'aria-label': 'weight' }}
                            value={description}
                            onChange={(event) => handleGroupNameChange(event, setDescription)}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ maxWidth: "25ch" }}
                        disabled={groupName.trim().length == 0}
                        onClick={handleCreateGroup}>Create Group</Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export const JoinGroup = ({ sx }) => {
    const GROUP_HASH_LENGTH = 8
    const [groupHash, setGroupHash] = useState("")
    const [group, setGroup] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const user = useContext(UserContext)
    const [_, setGroupsRefresh] = useContext(GroupsResetContext)
    function handleGroupHash(event, setFunction) {
        let text = event.target.value;
        if (text.length > GROUP_HASH_LENGTH) {
            event.preventDefault()
            return
        }
        setFunction(text)
    }
    useEffect(() => {
        if (groupHash.length != GROUP_HASH_LENGTH) {
            setGroup(null)
            return
        }
        let controller = new AbortController()
        setIsLoading(true)
        getGroupByHash(groupHash, controller.signal).then(resp => {
            setGroup(resp)
        }).catch(e => {
            setGroup(null)
        }).finally(() => setIsLoading(false))
        return () => {
            controller.abort();
        }
    }, [groupHash])

    function handleJoinGroup() {
        joinGroup(user, group.group_id, user.email)
            .then((_) => {
                setGroupsRefresh({})
                setGroupHash("")
            }).catch((err) => {
                console.log("Unable to join group:", err)
            })
    }
    return (
        <Accordion sx={sx}>
            <AccordionSummary expandIcon={<Send />}
                aria-controls="panel1-content"
                id="panel1-header"><Typography variant="body2">JOIN</Typography></AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Group secret</InputLabel>
                        <OutlinedInput
                            variant="outlined"
                            label="Group secret"
                            endAdornment={<InputAdornment position="end">{GROUP_HASH_LENGTH - groupHash.length}</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{ 'aria-label': 'weight' }}
                            value={groupHash}
                            onChange={(event) => handleGroupHash(event, setGroupHash)}
                        />
                    </FormControl>
                    {group != null && group.group_name == null
                        ? <Typography variant="subtitle2" color="error">Not found...</Typography> : <></>}
                    {
                        group?.group_name != null ?
                            <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
                                <Typography p={1}>{group.group_name}</Typography>
                                <Button
                                    variant="contained"
                                    sx={{ maxWidth: "25ch" }}
                                    onClick={handleJoinGroup}
                                    disabled={group?.group_id == null}>Join Group</Button>
                            </Box> : isLoading ? <Loader /> : <></>
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}