import { useContext, useEffect, useState } from "react"
import { deleteExpense, getExpenses } from "../backend"
import { DialogContext, GroupDetailContext, GroupResetContext, TargetGroupContext, UserContext } from "../contexts"
import { Grid, Stack, Box, Typography, Divider, Button, IconButton, DialogContent } from "@mui/material"
import { CURRENCIES } from "../models"
import { formatCurrencyNumber, formatHumanReadableNumber } from "../utils"
import { Delete } from "@mui/icons-material"
import { FlitwiseDialogInformation } from "./Dialogue"

const PersonalAmount = ({ amount, ccy, is_deleted }) => {
    let color = parseFloat(amount) < 0 ? "red" : "green"
    return <Grid item xs={2} sm={1}>
        {is_deleted
            ? <Typography variant="subtitle2"> deleted </Typography>
            : amount != 0
                ? <><Typography color={color}>{amount}</Typography><Typography color={color} variant="subtitle2">{ccy}</Typography></>
                : <Typography variant="subtitle2">{is_deleted ? "deleted" : "not involved"}</Typography>
        }</Grid>
}



export const Expense = ({ expense }) => {
    let { users } = useContext(GroupDetailContext)
    let { is_deleted } = expense
    let user = useContext(UserContext)
    let [_d, setDialogue] = useContext(DialogContext)
    const [showDetails, setShowDetails] = useState(false)
    const [_g, setGroupReset] = useContext(GroupResetContext)
    let created_time = expense.created_at
    const month = created_time.toLocaleString('default', { month: 'long' })
    const day = created_time.toLocaleString('default', { day: "numeric" })
    const payers = expense.splits.filter(x => x.is_payer)
    const loaners = expense.splits.filter(x => !x.is_payer && parseFloat(x.archived_amount) < 0)
    const ccy = CURRENCIES[expense.currency_id].value
    const personal_amount = 100 * expense.splits.filter(x => x.user_id == user.userId)
        .reduce((x, y) => (x + parseFloat(y.archived_amount)), 0) / 100
    let backgroundColor = showDetails ? "#EAEAEA" : null
    function openDeleteConfirmation() {
        setDialogue(new FlitwiseDialogInformation(
            "Delete expense",
            "Are you sure you want to delete this expense?",
            "Delete",
            "Cancel",
            handleDelete,
        ))
    }
    function handleDelete() {
        deleteExpense(user, expense.expense_id, expense.group_id, expense.currency_id)
            .then(resp => {setGroupReset({})}).catch(e => { console.log(e) })
    }
    return (
        <Grid mb={0.5} pb={0.5} backgroundColor={backgroundColor} container key={expense.expense_id} p={0.6} sx={{ borderRadius: "3px" }}>
            <Grid item xs={10} sm={11} onClick={() => { setShowDetails(!showDetails) }}>
                <Box display="flex" flexDirection="row" >
                    <Box display="flex" flexDirection="column" paddingRight={3}>
                        <Typography variant="body1">{month}</Typography>
                        <Typography variant="body1">{day}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Typography sx={{ textDecoration: is_deleted ? "line-through" : "default" }}>{expense.description}</Typography>
                        <Stack>
                            {payers.map(payer => (
                                <Typography key={payer.user_id} variant="body2">{`${users[payer.user_id].display_name} paid ${formatCurrencyNumber(ccy, payer.archived_amount)}`}</Typography>))}
                        </Stack>
                        {
                            (showDetails) ? <Stack>{loaners.map(loaner => (
                                <Typography key={loaner.user_id} variant="subtitle2">{`- ${users[loaner.user_id].display_name} owes ${formatCurrencyNumber(ccy, Math.abs(loaner.archived_amount))}`}</Typography>
                            ))}<Box mt={1} /><Button sx={{ width: "20px", height: "20px" }} size="small"
                                onClick={(event) => { openDeleteConfirmation(); event.stopPropagation() }} disabled={is_deleted} color="error" variant="outlined">delete</Button>
                            </Stack> : <></>
                        }
                    </Box>
                </Box>
            </Grid>
            <PersonalAmount amount={formatHumanReadableNumber(personal_amount)} ccy={ccy} is_deleted={is_deleted} />
        </Grid>

    )
}