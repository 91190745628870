
import { Container, Grid, Box, CircularProgress } from "@mui/material";

export const Loader = () => {
    return (<Box
        sx={{
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 1,
        }}><CircularProgress/></Box>)
}