import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { connectedSignInWithRedirect } from "../firebase/auth";

export const LoginView = () => {
    return (
        <Box
            sx={{
                pt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: "secondary.main" }}>
                <Typography variant="h3" color="white">Flitwise</Typography>
            </Box>
            <Box m={1} />
            <Button variant="outlined"
                onClick={() => {
                    connectedSignInWithRedirect()
                }}>Sign in with Google</Button>
        </Box>
    );
};
