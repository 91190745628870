import { Accordion, AccordionSummary, Stack, Typography } from "@mui/material"
import { CURRENCIES } from "../models"
import { formatCurrencyNumber } from "../utils"
import { ExpandMore } from "@mui/icons-material"

export const AllPayments = ({ sx, groupDetail }) => {
    let { users, suggested_payments } = groupDetail
    return <Accordion elevation={1} sx={{...sx, backgroundColor: "#"}}>
        <AccordionSummary expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header">All Payments</AccordionSummary>
        <Stack mx={2} mb={1}>
            {
                suggested_payments
                    .map(payment => (
                        <Typography key={`${payment.currency_id}_${payment.from_user_id}_${payment.to_user_id}`} variant="subtitle2"
                        >{`${users[payment.from_user_id].display_name} owes ${users[payment.to_user_id].display_name} ${formatCurrencyNumber(CURRENCIES[payment.currency_id].value, payment.amount)}`}</Typography>
                    ))
            }
        </Stack></Accordion>
}
