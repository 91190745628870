import React, { useContext } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { TargetGroupContext, UserContext } from "../contexts";
import { auth } from '../firebase/auth';
import { Logout, Home } from "@mui/icons-material";

export default function Topbar() {
    const user = useContext(UserContext)
    const [_, setTargetGroup] = useContext(TargetGroupContext)
    return (
        <Box sx={{backgroundColor: "background.default"}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{py: 1}}>
                <IconButton color="primary" onClick={() => {setTargetGroup(null)}}><Home /></IconButton>
                <Typography >{user.displayName}</Typography>
                <IconButton color="primary" onClick={() => { auth.signOut() }}><Logout /></IconButton>
            </Box>
        </Box>
    )
}