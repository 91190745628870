import React, { useEffect, useState } from "react";
import { LoginView } from "./views/LoginView";
import { IndexView } from "./views/IndexView";
import { auth } from "./firebase/auth"
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles"
import { Box, Container } from "@mui/material"
import { DialogContext, UserContext } from './contexts';
import { useAuthState } from 'react-firebase-hooks/auth';
import CssBaseline from '@mui/material/CssBaseline';
import { patchUser } from "./backend";
import { Loader } from "./components/Loader";
import { FlitwiseDialogue } from "./components/Dialogue";

let theme = createTheme({
  palette: {
    mode: "light",
    background: {
      "default": "#f4f3ee",
      "paper": "#EFECE8"
    },
    primary: {
      "main": "#6c9a8b"
    },
    secondary: {
      "main": "#94d1be"
    }
  },

});
theme = createTheme(theme, {
  components: {
    // MuiAccordion: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#fafafa',
    //     },
    //   },
    // },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#fafafa',
    //     },
    //   },
    // }
  },
  typography: {
    h5: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    body1: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
    },
    body2: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 12,
      },
    },
    subtitle2: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 11,
      },
    },
    caption: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 10,
      },
    },
  },
})

const useStyles = makeStyles({
  indexContainer: {},
  indexBox: {
    minHeight: "100vh",
  },
});

function App() {
  const [user, loading, error] = useAuthState(auth)
  let [userId, setUserId] = useState(null)
  const [dialogue, setDialogue] = useState(null)
  useEffect(() => {
    if (user == null) return () => {};
    patchUser(user)
      .then(({ data: { data } }) => {
        setUserId(data)
      }).catch((err) => {
        console.log("cannot patch user", err)
      })
    return () => { }
  }, [user?.email])
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={{ ...user, userId: userId }}>
        <DialogContext.Provider value={[dialogue, setDialogue]}>
          <FlitwiseDialogue />
          <CssBaseline />
          <Box width="100%" sx={{ bgcolor: "background.default" }}>
            <Container maxWidth="md" sx={{ bgcolor: "background.default", minHeight: "100vh", px: 1 }} className={classes.indexContainer}
            >
              {
                (loading || (user != null && userId == null))
                  ? <Loader />
                  : (userId != null && user != null) ? <IndexView /> : <LoginView />
              }
            </Container>
          </Box>
        </DialogContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
