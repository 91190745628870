import { CURRENCIES } from "../models"
import { Stack, Typography, IconButton, Divider } from "@mui/material";
import { formatHumanReadableNumber } from "../utils";

export const BalanceSummary = ({ user, balances }) => {
    return (<Stack>
        {
            balances
                .filter(balance => balance.user_id == user.userId && parseFloat(balance.balance) != 0)
                .map(balance => (
                    <Typography key={`${balance.currency_id}_${balance.user_id}`} variant="subtitle2"
                        color={parseInt(balance?.balance) > 0 ? "green" : "red"}>{CURRENCIES[balance.currency_id].value} {formatHumanReadableNumber(balance.balance)}</Typography>
                ))
        }
    </Stack>)
}