import { useContext } from "react";
import { CURRENCIES } from "../models"
import { Grid, Box, Stack, Typography, IconButton, Divider } from "@mui/material";
import { GroupDetailContext, UserContext } from "../contexts";

export const SuggestedPayments = () => {
    let user = useContext(UserContext)
    let { users, _, suggested_payments } = useContext(GroupDetailContext)
    let payments_to_recv = suggested_payments.filter(payment => payment.to_user_id == user.userId);
    let payments_to_send = suggested_payments.filter(payment => payment.from_user_id == user.userId);
    return (
        <Box marginY={1}>
                <Stack>
                    {
                        payments_to_recv
                            .map(payment => (
                                <Typography key={`${payment.currency_id}_${payment.from_user_id}`} variant="subtitle2" color="green"
                                >{`${users[payment.from_user_id].display_name} owes you ${CURRENCIES[payment.currency_id].value} ${payment.amount}`}</Typography>
                            ))
                    }
                </Stack>
                <Stack>
                    {
                        payments_to_send
                            .map(payment => (
                                <Typography key={`${payment.currency_id}_${payment.from_user_id}`} variant="subtitle2" color="red"
                                >{`you owe ${users[payment.to_user_id].display_name} ${CURRENCIES[payment.currency_id].value} ${payment.amount}`}</Typography>
                            ))
                    }
                </Stack>
        </Box>)
}