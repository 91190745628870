import { useContext, useEffect, useState } from "react"
import { getExpenses } from "../backend"
import { GroupResetContext, TargetGroupContext, UserContext } from "../contexts"
import { Stack } from "@mui/material"
import { Expense } from "./ExpenseItem"

export const ExpensesList = ({hideDeleted}) => {
    const user = useContext(UserContext)
    let [targetGroup] = useContext(TargetGroupContext)
    let [groupRefresh, _] = useContext(GroupResetContext)
    let [expenses, setExpenses] = useState([])
    useEffect(() => {
        getExpenses(user, targetGroup.group_id).then((resp) => {
            resp = Object.values(resp).map(expense => ({...expense, created_at: new Date(expense.created_at) }))
            resp.sort((x, y) => (y.created_at - x.created_at))
            setExpenses(resp)
        })
        return () => { }
    }, [groupRefresh])
    return (
        <Stack marginTop={2}>
            {Object.values(expenses).filter(x => !(hideDeleted && x.is_deleted)).map(expense => ( 
                <Expense key={expense.expense_id} expense={expense}/>
            ))}
        </Stack>
    )

}