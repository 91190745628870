import { Stack, Avatar, Tooltip } from "@mui/material";
import { Loader } from "../components/Loader";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(email, name, sx = {}) {
    const short_name = name.split(" ").filter(x => x != "")
        .map(x => x[0].toUpperCase()).join('')
    return {
        sx: {
            ...sx,
            bgcolor: stringToColor(email),
        },
        children: short_name,
    };
}
export const AvatarOrDefault = ({ user, sx = { height: '35px', width: '35px' } }) => {
    return (
        <Tooltip title={user.display_name}>
            {(user.photo_url != null)
                ? <Avatar size="small" sx={{ ...sx }} alt={user.display_name} src={user.photo_url} />
                : <Avatar size="small" {...stringAvatar(user.email, user.display_name, sx)} />}
        </Tooltip>
    );
}

export const AvatarStack = ({ users }) => {
    return (
        (users == null)
            ? <Loader />
            : <Stack direction="row" spacing={-2} alignItems="center">
                {Object.values(users).map((user, key) => (
                    <AvatarOrDefault user={user} key={key} />
                ))}
            </Stack>
    );
}