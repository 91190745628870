import { Checkbox, Stack, Box, Typography } from "@mui/material";
import { React, useContext, useRef, useState } from "react";
import { GroupDetailContext, SplitContext } from "../../contexts";
import { AvatarOrDefault } from "../AvatarStack";

export const EqualSplit = () => {
    var { users } = useContext(GroupDetailContext)
    var [amount, splits, currency] = useContext(SplitContext)
    var initialUsersToSplit = Object.fromEntries(Object.values(users).map(user => ([user.user_id, true])));
    var [usersToSplitWith, setUsersChecked] = useState(initialUsersToSplit)

    function handleCheckbox(user_id) {
        setUsersChecked(prev => {
            let new_state = { ...prev }
            new_state[user_id] = !new_state[user_id]
            return new_state
        })
    }
    const numUsers = Object.values(usersToSplitWith).reduce((x, y) => x + (y ? 1 : 0));
    const splitAmount = parseFloat(amount) / Math.max(numUsers, 1);
    splits.current = Object.fromEntries(
        Object.entries(usersToSplitWith).map(([user_id, should_split], k) => [user_id, should_split ? splitAmount : 0])
    )
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" sx={{ mx: 1 }}>
            {Object.values(users).map((user, key) => (
                <Box key={user.user_id} display="flex" justifyContent="space-between" sx={{ alignItems: "center", p: 0.2 }}>
                    <AvatarOrDefault user={user} />
                    <Typography>{user.display_name}</Typography>
                    <Checkbox size="small"
                        checked={usersToSplitWith[user.user_id]}
                        onChange={() => handleCheckbox(user.user_id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
            ))}
            <Box />
            <Typography align="center" variant="subtitle2" marginY={0.5}>{Math.round(splitAmount * 100) / 100} {currency.value} / person</Typography>
        </Box>
    )
}