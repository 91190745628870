import { Stack, Box } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { GroupsResetContext, UserContext } from "../contexts";
import { getGroups } from "../backend";
import { AddNewGroup, GroupItem, JoinGroup } from "../components/GroupItem";
import { Loader } from "../components/Loader";
import { HorizontalRule } from "@mui/icons-material";

export const Home = () => {
    let user = useContext(UserContext)
    let [groupsRefresh, setGroupsRefresh] = useState({})
    let [groups, setGroups] = useState([])
    useEffect(() => {
        if (user == null) {
            console.log("ERROR: user is set before successful login")
            return
        }
        getGroups(user).then(resp => {
            setGroups(resp.data.data)
        })
        return () => { }
    }, [])

    return (
        <GroupsResetContext.Provider value={[groupsRefresh, setGroupsRefresh]}>
            <AddNewGroup sx={{ borderRadius: 1, my: 0.5 }} />
            <JoinGroup sx={{ borderRadius: 1, my: 0.5 }} />
            <HorizontalRule />
            <Box p={1} />
            {
                (groups == null)
                    ? <Loader />
                    : <Stack direction="column" spacing={1}>
                        {groups.map((group, key) => (
                            <GroupItem index={group.group_id} group={group} key={key} />
                        ))}
                    </Stack>
            }
            <Box p={3} />
        </GroupsResetContext.Provider>
    )

}