import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useContext } from "react"
import { DialogContext } from "../contexts"

export class FlitwiseDialogInformation {
    constructor(title, description, confirmationText, cancelText, onConfirm) {
        this.title = title
        this.description = description
        this.confirmationText = confirmationText
        this.cancelText = cancelText
        this.onConfirm = onConfirm

    }
}

export const FlitwiseDialogue = () => {
    let [dialogue, setDialogue] = useContext(DialogContext)
    return <Dialog open={dialogue != null}>
        <DialogTitle>{dialogue?.title}</DialogTitle>
        <DialogContent>{dialogue?.description}</DialogContent>
        <DialogActions>
            <Button size="small" onClick={() => { dialogue?.onConfirm(); setDialogue(null) }}>Ok</Button>
            <Button size="small" onClick={() => { setDialogue(null) }} autoFocus>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>
}